::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(133, 112, 112);
}
.overflow-y-auto{
  overflow-y: auto!important;
}
.overflow-x-auto{
  overflow-x: auto!important;
}
/* .nav-overflow>ul{
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 46px);
} */
.main-content{
  /* background-color: #f5f5f5!important; */
  width: 100%!important;
  height: calc(100vh - 20px)!important;
  overflow: auto;
  /* border: 1px solid rgb(211, 96, 96); */
  margin: 10px;
  padding: 10px;
}
.ant-page-header.ant-page-header-ghost{
  padding: 0px;
}
.req::after{
  content: ' *';
  color: red;
}
.text-bold{
  font-weight: bold;
}
.app-modal-body-overflow .ant-modal-body, .app-modal-without-footer-body-overflow .ant-modal-body {
  max-height: calc(100vh - 200px)!important;
  display: flex;
  flex-direction: column;
  overflow: auto!important;
}
.app-modal-without-footer-body-overflow .ant-modal-body{
  max-height: calc(100vh - 100px)!important;
}
.ant-modal-fullscreen {
  width: 100vw!important;
  height: 100vh;
  top: 0px!important;
  max-width: none!important;
  margin: 0!important;
}
.ant-modal-fullscreen .ant-modal-body{
  height: calc(100vh - 110px)!important;
  display: flex;
  flex-direction: column;
  overflow: auto!important;
}
.ant-modal-fullscreen .ant-modal-content {
  height: 100vh;
  width: 100vw;
}

.ant-tabs-nav{
  margin-bottom: 0px!important;
}
/* .ant-tabs-content-holder{
  padding: 10px;
  overflow: auto;
  height: calc(100vh - 204px)
} */
.ant-tabs-tab{
  border-top: 2px solid transparent!important;
}
.ant-tabs-tab.ant-tabs-tab-active{
  border-top: 2px solid red!important;
}

.ant-message .anticon {
  top: -3px!important;
}

.ant-pagination{
  display: inline-flex;
}
.d-flex{
  display: flex!important;
}
.ant-page-header{
  padding: 0px;
}
.main-content .ant-card-small > .ant-card-body:first-child{
  padding-top: 0px;
}
.ant-col .ant-form-item{
  margin-bottom: 10px;
}
.ant-col.ant-form-item-label{
  padding: 0px!important;
}

/* Loader Page CSS Starts */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Loader Page CSS Ends */
.tox-notification.tox-notification--in.tox-notification--warning, .tox-statusbar__branding{
  display: none!important;
}

.search-form .ant-row div.ant-col:last-child{
  margin-left: auto;
}
.cp{
  cursor: pointer;
}
.ant-message .anticon{
  position: static!important;
}
ul.ant-menu-horizontal li:first-child{
  margin-left: auto;
}
.role-module-checkbox{
  display: flex!important;
  flex-wrap: wrap;
}
.role-module-checkbox label{
  width: 25%;
  margin-right: 0px;
}
.w-100{
  width: 100%!important;
}
.w-25{
  width: 25%!important;
}
.w-50{
  width: 50%!important;
}
.w-75{
  width: 75%!important;
}



#invoice {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#invoice th,
#invoice td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

#invoice th {
  background-color: black;
  color: white;
}

#total {
  text-align: right;
  margin-top: 20px;
}



.invoice-box {
  /* max-width: 800px; */
  margin: auto;
  /* padding: 30px; */
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}
.invoice-box table tr td:nth-child(2) {
  text-align: right;
}
.invoice-box table tr.top table td {
  padding-bottom: 20px;
}
.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}
.invoice-box table tr.information table td {
  padding-bottom: 40px;
}
.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.invoice-box table tr.details td {
  padding-bottom: 20px;
}
.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}
.invoice-box table tr.item.last td {
  border-bottom: none;
}
.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}
.invoice-box.rtl table {
  text-align: right;
}
.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}